<template>
  <div class="container">
    <el-container class="home">
      <el-header class="app_header">
        <el-button class="mainButton homeBtn" type="text" @click="$router.push('/home')">DAWN</el-button>
        <el-button class="mainButton backBtn" icon="el-icon-back" @click="goBack">返回&nbsp | &nbsp&nbsp&nbsp个人经验库</el-button>
      </el-header>
      <el-main class="belowTop">
        <div id="pageName">
          {{ viewingUser }}经验库
          <el-button v-if="viewingUser !== '我的'" class="postBtn followBtn" @click="toggleFollow()">
            {{ followedText }}
          </el-button>
        </div>
        <div class="postsDiv">
          <el-card v-for="item in posts" :key="item.cardId" @click.native="handlePostClick(item)">
            <el-button v-if="!notMyPosts" class="postBtn deleteBtn" icon="el-icon-delete" @click="handleDelete(item)"/>
            <div v-if="notMyPosts" class="cardDiv nickname">{{ item.nickname }}</div>
            <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
            <div class="cardDiv postTitle">{{ item.file_name }}</div>
            <div class="cardDiv postContent">{{ item.content }}</div>
            <div class="comment postInfo"><i class="el-icon-chat-line-square"></i>评论 {{ item.commentNumber }}</div>
            <el-button v-if="notMyPosts" class="postBtn likeBtn" icon="el-icon-thumb" @click="toggleLike(item)">{{ item.likeText }} {{ item.likeNumber }}</el-button>
            <div v-else class="infoRight postInfo"><i class="el-icon-chat-line-thumb"></i>赞 {{ item.likeNumber }}</div>
            <el-button v-if="notMyPosts" class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{ item.collectText }} {{ item.collectNumber }}</el-button>
            <div v-else class="infoRight postInfo"><i class="el-icon-chat-line-star-off"></i>收藏 {{ item.collectNumber }}</div>
          </el-card>
          <div class="noInfo" v-if="this.posts.length === 0">暂无帖子</div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>   
<script>
  import {follow_user, unfollow_user, get_my_posts, get_user_posts, delete_post, get_all_following,
      collect_post, uncollect_post, like_post, unlike_post} from "@/network/community";
  import {starLoading} from "@/utils/common";

  export default {
    name: "MyPosts",
    data() {
      return {
        posts: [],
        notMyPosts: false,
        viewingUser: '',
        email: '',
        followedText: '',
        uid: '',
      };
    },
    methods: {
      goBack() {
        this.$router.back();
      },
      handleDelete(item) {
        event.stopPropagation();
        this.$confirm('是否要删除帖子', '系统提示', {
          confirmButtonText: '确认',
          cancelButtonText: '返回',
          type: 'info'
        }).then(() => {
          delete_post(this.email, item.cardId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已删除帖子'
              });
              this.loadingInstance  = starLoading();
              this.get_all_my_posts();
            }
            else {
              this.$message({
                type: 'warning',
                message: '删除帖子失败，请稍后重试'
              });
            }
          })
        }).catch(() => {});
        document.activeElement.blur();
      },
      handlePostClick(item) {
        item['followedText'] = this.followedText;
        this.$router.push({
          name: 'post', params: { 
            post: item
          }
        });
      },
      toggleFollow() {
        event.stopPropagation();
        if (this.followedText !== '已关注') {
          follow_user(this.email, this.uid).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已关注'
              });
            }
            else {
              this.$message({
                type: 'warning',
                message: '取消关注失败，请稍后重试'
              });
            }
          })
        }
        else {
          unfollow_user(this.email, this.uid).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已取消关注'
              });
            }
            else {
              this.$message({
                type: 'warning',
                message: '取消关注失败，请稍后重试'
              });
            }
          })
        }
        this.followedText = this.followedText === '已关注'? '+关注' : '已关注';
        document.activeElement.blur();
      },
      toggleLike(item) {
        event.stopPropagation();
        item.likeText = (item.isLiked === 1 )? '点赞' : '已赞';
        if (item.isLiked === 0) {
          item.isLiked = 1;
          item.likeNumber ++;
          like_post(this.email, item.cardId, item.ownerId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已点赞'
              });
              this.fetchPosts();
            }
            else {
              item.isLiked = 0;
              item.likeNumber --;
              item.likeText = (item.isLiked === 1 )? '点赞' : '已赞';
              this.$message({
                type: 'warning',
                message: '取消点赞失败，请稍后重试'
              });
            }
          })
        }
        else {
          item.isLiked = 0;
          item.likeNumber --;
          unlike_post(this.email, item.cardId, item.ownerId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已取消点赞'
              });
              this.fetchPosts();
            }
            else {
              item.isLiked = 1;
              item.likeNumber ++;
              item.likeText = (item.isLiked === 1 )? '点赞' : '已赞';
              this.$message({
                type: 'warning',
                message: '取消点赞失败，请稍后重试'
              });
            }
          })
        }
        document.activeElement.blur();
      },
      toggleCollect(item) {
        event.stopPropagation();
        item.collectText = item.collectText === '已收藏'? '收藏' : '已收藏';
        if (item.isCollect === 0) {
          item.isCollect = 1;
          item.collectNumber ++;
          collect_post(this.email, item.cardId, item.ownerId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已收藏'
              });
            }
            else {
              item.isCollect = 0;
              item.collectNumber --;
              item.collectText = item.collectText === '已收藏'? '收藏' : '已收藏';
              this.$message({
                type: 'warning',
                message: '收藏失败，请稍后重试'
              });
            }
          })
        }
        else {
          item.isCollect = 0;
          item.collectNumber --;
          uncollect_post(this.email, item.cardId, item.ownerId).then((res) => {
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '已取消收藏'
              });
            }
            else {
              item.isCollect = 1;
              item.collectNumber ++;
              item.collectText = item.collectText === '已收藏'? '收藏' : '已收藏';
              this.$message({
                type: 'warning',
                message: '取消收藏失败，请稍后重试'
              });
            }
          })
        }
      },
      get_followings() {
        get_all_following(this.email).then((res) => {
          if (res !== undefined && res.data !== undefined) {
            this.following = res.data; //后端数据查询时以控制有序
            let result = this.following.map(obj => obj.uid);
            this.followedText = (result.includes(this.uid)) ? '已关注' : '+关注';
          }
          else {
            this.following = [];
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      get_all_my_posts() {
        get_my_posts(this.email).then((res) => {
          if (res !== undefined && res.data !== undefined) {
            this.posts = res.data; //后端数据查询时以控制有序
          }
          else {
            this.posts = [];
          }
          this.loadingInstance.closeLoading();
        }).catch((error) => {
          console.log(error);
        });
      },
    },
    created() {
      this.loadingInstance  = starLoading();
      this.email = this.$store.getters.email;
      if (this.$route.params.uid === undefined || this.$route.params.uid === null) {
        this.uid = this.$store.getters.viewing_posts;
      }
      else {
        this.uid = this.$route.params.uid;
        this.$store.commit("SET_VIEWING_POSTS", this.$route.params.uid);
      }
      this.get_followings();
      if (this.uid === this.email) { //我的帖子 & 我的经验库
        this.viewingUser = "我的";
        this.get_all_my_posts();
      }
      else { //别人的经验库
        this.notMyPosts = true;
        get_user_posts(this.email, this.uid).then((res) => {
          console.log(res);
          if (res && res.data && res.data.length !== 0) {
            this.posts = res.data; //后端数据查询时以控制有序
            this.posts.forEach(post => {
              post['collectText'] = post.isCollect == 0 ? '收藏' : '已收藏';
              post['likeText'] = post.isLiked == 0 ? '点赞' : '已点赞';
            });
            this.viewingUser = this.posts[0].nickname + "的";
          }
          else {
            this.posts = [];
          }
          this.loadingInstance.closeLoading();
        }).catch((error) => {
          console.log(error);
        });
      }
    }
  }
</script>

<style scoped>
  .el-header {
    position: fixed;
    top: 0;
    left:0;
    width:100%;
    justify-content: center;
    align-items: center;
    background-color: #7a557b;
    z-index: 1000;
  }

  .mainButton {
    position: absolute;
    top: 0;
    margin-top: 0;
  }

  .homeBtn {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 30px;
    margin-left: 30px;
    padding: 0;
    top: 3px;
    left: 0;
  }

  .app_header .el-button {
    color: white;
    margin: 10px;
  }

  #pageName {
    width: 70%;
    margin: 20px auto;
    font-size: 30px;
  }

  .backBtn {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    color: white;
    background-color: transparent;
    margin-top: 20px;
    left: 100px;
    border: none;
    font-size: 15px;
  }
  .home {
    overflow: hidden;
  }
  .el-container {
    margin: 0px;
  }
  .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .belowTop {
    text-align: center;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }

  .noInfo {
    margin: 20px 0;
    border: solid 1px #6c6c6c;
    padding: 40px;
    border-radius: 5px;
  }
  
  .postsDiv {
    width: 70%;
    margin: 0 auto;
  }
  .cardDiv {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .el-card {
    text-align: start;
  }
  .postBtn {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    align-items: center;
    padding: 5px;
    float: right;
    font-size: small;
    height: 25px;
    width: 50px;
    margin: 10px 0;
    border: none;
    color: #7a557b;
  }
  .followBtn {
    margin-right: 50px;
    border: 1px dashed #7a557b;
  }
  .followBtn:hover {
    background-color: #7a557b;
    border: 0;
    color: #f3e6f6;
  }
  .nickname {
    font-size: 12px;
  }
  .likeBtn {
    width: 80px;
    border: 1px #7a557b;
  }
  .likeBtn:focus {
    background-color: white !important;
    border: 0;
  }
  .likeBtn:hover {
    background-color: #7a557b;
    border: 0;
    color: #f3e6f6;
  }
  .collectBtn {
    width: 100px;
    margin-right: 5px;
    border: 1px #7a557b;
  }
  .collectBtn:hover {
    background-color: #7a557b;
    border: 0;
    color: #f3e6f6;
  }
  .deleteBtn:hover {
    background-color: transparent;
    border: 0;
    color: #f3e6f6;
  }
  .postTitle {
    font-size: 16px;
    margin-top: 10px;
  }
  .postTime {
    margin: 5px 0;
    font-size: 11px;
  }
  .postInfo {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    font-size: small;
    height: 25px;
    margin: 10px 0;
    color: #7a557b;
    align-content: center;
    color: #333;
  }
  .comment {
    float: left;
  }
  .infoRight {
    float: right;
    margin-left: 10px;
  }
  .el-icon-chat-line-square {
    padding-right: 5px;
  }
  .postContent {
    margin-top: 20px;
    font-size: 13px;
  }

</style>