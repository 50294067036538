<template>
    <div class="report">
        <div class="papers main-rate" style="page-break-after: always;width: 840px">
            <img src="../assets/img/title-1@2x.png" class="a4_title" alt="">
            <div class="a4_title_en">ZHONG KE FANG QIU</div>
            <img src="../assets/img/polygon 1@2x.png" class="a4_polygon1" alt="">
            <img src="../assets/img/bg 1@2x.png" class="a4_bg" alt="">
            <img src="../assets/img/polygon 2@2x.png" class="a4_polygon2" alt="">
            <img src="../assets/img/rectangle 1@2x.png" class="a4_rectangle" alt="">
            <img src="../assets//img/REPORT@2x.png" class="a4_report" alt="">
            <div class="a4_content">
                <div class="a4_content_title">大学生卓越计划</div>
                <div class="a4_content_title">保研深度定位报告</div>
                <div style="margin-top: 10px;">有理想、有信念、</div>
                <div>有目标、有责任</div>
            </div>
            <div class="a4_footer">
                夏怡同学保研专属定制方案
            </div>
        </div>

        <div class="papers main-rate" style="page-break-after: always;width: 840px">
            <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
            <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
            <div class="a4_mian_footer">
                本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
            </div>
        </div>

        <div class="papers main-rate" style="page-break-after: always;width: 840px">
            <img src="../assets/img/rectangle 2@2x.png" class="a4_main_rectangle2" alt="">
            <img src="../assets/img/polygon 2@2x.png" class="a4_main_polygon2" alt="">
            <img src="../assets/img/rectangle 1@2x.png" class="a4_main_rectangle" alt="">
            <div class="a4_mian_footer">
                本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'index',
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.report {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.papers {
    width: 1200px;
    min-height: 1188px;
    box-shadow: -1px -1px 26px -3px rgba(0, 0, 0, 0.3);
    background-color: #F2F1F2;
    border-radius: 2px;
    /* padding: 30px; */
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: 20px;
    font-family: STKaiti, STKaiti;
    color: #000;
    display: inline-block;
    position: relative;
}

.a4_title {
    width: 55%;
    position: absolute;
    top: 11%;
    left: 4.3%;
    z-index: 99;
}

.a4_title_en {
    font-size: 30px;
    color: #7A557B;
    position: absolute;
    top: 23.5%;
    left: 8%;
    z-index: 99;
}

.a4_polygon1 {
    width: 36.8%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.a4_bg {
    width: 100%;
    position: absolute;
    top: 8.1%;
    left: 0;
    z-index: 2;
}

.a4_polygon2 {
    width: 61%;
    position: absolute;
    left: 0;
    top: 48.7%;
}

.a4_rectangle {
    width: 61%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.a4_report {
    width: 50%;
    position: absolute;
    left: 3%;
    bottom: 2%;
}

.a4_content {
    width: 30%;
    position: absolute;
    right: 5%;
    top: 51%;
    text-align: left;
    color: #7A557B;
    font-size: 20px;
    line-height: 30px;
}

.a4_content_title {
    font-size: 30px;
    line-height: 45px;
    font-weight: bold;
    font-family: STXinwei, STXinwei;
}

.a4_footer {
    width: 100%;
    position: absolute;
    bottom: 0.5%;
    font-size: 30px;
    color: #494949;
}

.mian_bg-1{
    width: 100%;
    position: absolute;
    right: 0;
    /* top: 0; */
}
.mian_bg-2{
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
}

.a4_mian_footer{
    width: 100%;
    position: absolute;
    bottom: 2.5%;
    font-size: 14px;
    color: #333333;
}

.a4_main_rectangle2{
    width: 65%;
    position: absolute;
    right: 0;
    top: 0;
}

.a4_main_polygon2{
    width: 60%;
    position: absolute;
    left: 0;
    top: 25%;
}

.a4_main_rectangle{
    width: 60%;
    position: absolute;
    left: 0;
    top: 40%;
}

</style>