<template>
  <div style="text-align: center">
    <el-row type="flex" justify="center" class="formRow">
      <el-button class="backBtn" @click="goBack" icon="el-icon-back">返回</el-button>
      <div class="title">{{ pageTitle }}</div>
    </el-row>
    <el-form :model="planForm" ref="planForm" class="planFormClass" label-width="120px" :rules="rule" v-loading="loading">
      <el-form-item label="标题" prop="title">
        <el-input class="titleInput" v-model="planForm.title"></el-input>
      </el-form-item>
      <el-form-item label="时间" prop="planDate">
        <el-date-picker
          v-model="planForm.planDate"
          type="datetimerange"
          range-separator="至"
          start-placeholder="计划开始日期时间"
          end-placeholder="计划结束日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <!-- <el-input v-model="planForm.content"
          clearable type="textarea" :rows="18" class="inputReply" maxlength="3000" show-word-limit></el-input> -->
          <v-md-editor v-model="planForm.content" height="450px"></v-md-editor>
      </el-form-item>
      <el-form-item v-if="editingPlan" label="状态">
        <el-radio-group v-model="statusRadio">
          <el-radio-button :label="1">未开始</el-radio-button>
          <el-radio-button :label="2">正在进行中</el-radio-button>
          <el-radio-button :label="3">已结束</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="endBtns">
        <el-button class="btns addBtn" @click="submitForm">保存</el-button>
        <el-button class="btns clearBtn" @click="goBack">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { create_plan, update_plan } from '../network/intelligence';

export default {
  name: "PlanNew",
  data() {
    return {
      editingPlan: false,
      statusRadio: 1,
      planForm: {
        title: '',
        content: '',
        planDate: '',
      },
      loading: true,
      email: '',
      pageTitle: '新建计划书',
      rule: {
        planDate: [{ required: true, message: '计划开始和结束时间不能为空', trigger: 'blur' }]
      },
    }
  },
  methods: {
    goBack() {
      this.$confirm('离开当前页面会使未保存的内容丢失，是否确认', '系统提示', {
          confirmButtonText: '确认离开',
          cancelButtonText: '留在当前页面',
          type: 'info'
        }).then(() => {
          this.$router.back();
        }).catch(() => {});
        document.activeElement.blur();
    },
    submitForm() {
      this.$refs.planForm.validate((valid) => {
        if (valid) {
          let start = this.planForm.planDate[0].toLocaleString('en-ZA').replace(',','');
          let end = this.planForm.planDate[1].toLocaleString('en-ZA').replace(',','');
          if (this.editingPlan) {
            this.loading = true;
            update_plan(this.email, this.proId, this.planForm.title, this.planForm.content, start, end, this.statusRadio).then((res) => {
              this.handelRes(res);
              this.loading = false;
            })
            document.activeElement.blur();
          }
          else {
            this.loading = true;
            create_plan(this.email, this.planForm.title, this.planForm.content, start, end).then((res) => {
              this.handelRes(res);
              this.loading = false;
            })
            document.activeElement.blur();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handelRes(res) {
      if (res.flag === 1) {
        this.$message({
          type: 'success',
          message: '已保存'
        });
        this.$router.back();
      }
      else {
        this.$message({
          type: 'warning',
          message: '保存失败，请稍后重试'
        });
      }
    },
  },
  created() {
    this.email = this.$store.getters.email;
    let plan = {};
    if (this.$route.params.plan !== undefined && this.$route.params.plan !== null) {
      this.pageTitle = '我的计划书'
      plan = this.$route.params.plan;
      this.editingPlan = true;
      this.planForm.title = plan.title;
      this.planForm.content = plan.content;
      this.proId = plan.proId;
      this.statusRadio = plan.status;
      this.planForm.planDate = [new Date(plan.start_time), new Date(plan.due_time)];
    }
    this.loading = false;
  },
}
</script>

<style scoped>
::v-deep .el-form-item__content {
  text-align: start;
}
::v-deep .endBtns .el-form-item__content {
  text-align: center;
}
.btns {
  width: 200px;
  margin: 10px;
}
.btns:hover {
  border: #7a557b 1px solid;
  background-color: #9c849d;
  color: white;
}
.addBtn {
  border: #7a557b 1px solid;
  background-color: #7a557b;
  color: #f3e6f6;
}
.clearBtn {
  border: #7a557b 1px solid;
  background-color: white;
  color: #7a557b;
}
::v-deep .el-form-item__label {
  font-size: 15px;
  font-weight: 500;
}
.planFormClass {
  width: 95%;
}
.backBtn {
  height: 40px;
  align-items: center;
  margin-right: 20px;
  border: none;
}
.backBtn:hover {
  color: #f3e6f6;
  background-color: #7a557b;
}
.title {
  margin-right: 20px;
  border-radius: 10px;
  border: 1px #7a557b solid;
  background-color: white;
  color: #7a557b;
  text-align: center;
  align-content: center;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  width: 85%;
}
.formRow {
  margin: 20px;
}
.el-table {
  border-radius: 5px;
  margin: 10px auto;
}

.el-pagination {
  margin: 20px 0;
}
::v-deep .el-textarea__inner {
  padding: 10px;
}
::v-deep .el-select-dropdown__item.selected {
  color: #7a557b;
}

::v-deep .el-input__inner:focus, 
::v-deep .el-select .el-input.is-focus .el-input__inner, 
::v-deep .el-textarea__inner:focus,
::v-deep .el-range-editor.is-active, 
::v-deep .el-range-editor.is-active:hover {
  border-color: #7a557b;
}

::v-deep .el-input__inner, ::v-deep .el-date-editor .el-range-input, ::v-deep .el-textarea__inner {
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
}
.el-date-editor--datetimerange.el-input__inner {
  width: 70%;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #7a557b;
  color: #f3e6f6;
  border-color: #7a557b;
  box-shadow: -1px 0 0 0 #7a557b;
}

::v-deep .el-radio-button__inner:hover {
  color: #7a557b;
}
</style>