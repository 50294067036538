<template>
  <div class="container">
    <el-container class="home">
      <el-page-header @back="goBack" content="DAWN - 智能AI" />
      <el-main class="belowTop">
        <div class="boxes">
          <div class="box menu">
            <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" v-loading="windowsLoading"
              mode="vertical" @select="handleSelect" active-text-color="#7a557b">
              <el-menu-item v-for="item in windows" :index="item.windowId" @mouseenter.native="enter(item)"
                @mouseleave.native="leave(item)">
                <span slot="title" class="menuSpan">{{ item.title }}
                  <!-- <el-button v-if="item.btnVisible" class="iconBtn editBtn" icon="el-icon-edit-outline"></el-button> -->
                  <el-button v-if="item.btnVisible" class="iconBtn delBtn" icon="el-icon-close"
                    @click="handleDeleteWindow(item.windowId)"></el-button>
                </span>
              </el-menu-item>
              <el-button class="btns newWinBtn" @click="handleAddWindow">+新建窗口</el-button>
            </el-menu>
          </div>
          <div class="chatScreen" ref="scrollDiv">
            <div v-if="currentChat.length > 0" :class="isDrag ? 'flieDiv' : 'chats'" v-loading="chatsLoading">
              <div v-for="item in currentChat">
                <div class="chatBlockDiv">
                  <div class="speaker myselfSpeaker">{{ nickname }}</div>
                  <el-card class="chatCards qCard" :key="item.windownId + 'q'">
                    <div class="cardDiv">{{ item.question }}</div>
                  </el-card>
                </div>
                <div class="chatBlockDiv">
                  <div class="speaker aiSpeaker">夏怡</div>
                  <el-card class="chatCards ansCard" :key="item.windownId + 'a'">
                    <div class="cardDiv">{{ item.answer }}</div>
                    <!-- <pre class="cardDiv" v-if="!item.showTyping">{{ displayText }}<span class="cursor">|</span></pre>
                    <div class="cardDiv" v-else>{{ item.answer }}</div> -->
                  </el-card>
                </div>
                <!-- <el-card class="chatCards ansCard" :key="item.windownId + 'a'">
                  <div class="cardDiv" v-if="showTyping">{{ displayText }}</div>
                  <pre class="cardDiv" v-else>{{ displayText }}<span class="cursor">|</span></pre>
                </el-card> -->
              </div>

              <!-- <pre class="cardDiv">{{ displayText }}<span class="cursor">|</span></pre> -->
            </div>
            <div class="newScreen" v-else v-loading="chatsLoading">
              <div class="intro">您好！我是夏怡，是您的AI助手！</div>
              <div>
                <el-button class="nonBtn">保研</el-button>
                <el-button class="nonBtn">竞赛</el-button>
                <el-button class="nonBtn lastBtn">专业课</el-button>
              </div>
              <div>
                <el-button class="btns longChatBtns" @click="handleDefaultQ(1)">{{ defaultQ[1] }}</el-button>
                <el-button class="btns longChatBtns" @click="handleDefaultQ(3)">{{ defaultQ[3] }}</el-button>
                <el-button class="btns longChatBtns lastBtn" @click="handleDefaultQ(5)">{{ defaultQ[5] }}</el-button>
              </div>
              <div>
                <el-button class="btns longChatBtns" @click="handleDefaultQ(2)">{{ defaultQ[2] }}</el-button>
                <el-button class="btns longChatBtns" @click="handleDefaultQ(4)">{{ defaultQ[4] }}</el-button>
                <el-button class="btns longChatBtns lastBtn" @click="handleDefaultQ(6)">{{ defaultQ[6] }}</el-button>
              </div>
              <div>
                <el-button class="btns reportBtn lastBtn" @click="goToReport">制定并一键生成个人定位报告</el-button>
              </div>
            </div>
            <div class="dragDiv" v-if="isDrag">
              <div class="dragDiv_close">
                <i class="el-icon-close" style="float: right; cursor: pointer;" @click="isDrag = false"></i>
              </div>
              <div class="file-info" v-if="fileList.length">
                <i class="el-icon-folder-checked" style="margin-right: 20px;"></i>
                <div class="file-name">{{ fileList[0].name }}</div>
                <i class="el-icon-close" style="margin-left: 20px; cursor: pointer;" @click="handleRemove"></i>
              </div>
              <el-upload v-else class="drag-box" drag action="#" multiple :auto-upload="false" :limit=1
                :on-change="onFileChange" accept=".pdf,.doc,.docx,.txt" :on-remove="handleRemove"
                :on-exceed="handleExceed">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <!-- <div class="el-upload__tip" slot="tip">只能上传pdf,word,txt文件，且不超过10M</div> -->
              </el-upload>
              <div class="flie_input">
                <el-input class="" placeholder="请输入您的问题" v-loading="sendingQuestion" v-model="question" clearable
                  type="textarea" maxlength="200" :autosize="{ maxRows: 2 }" show-word-limit>
                </el-input>
                <el-button class="flie_question_btn" @click="handleFlieQuestion"
                  v-loading="sendingQuestion">提问</el-button>
              </div>
            </div>
            <div class="inputDiv" v-else>
              <el-button class="fileBtn" @click="isDrag = true">文件</el-button>
              <el-input class="inputBox" placeholder="请输入您的问题" v-loading="sendingQuestion" v-model="question" clearable
                @keyup.enter.native="handleQuestion" type="textarea" maxlength="200" :autosize="{ maxRows: 5 }"
                show-word-limit>
              </el-input>
              <el-button class="sendQuestionBtn" @click="handleAskQuestion" v-loading="sendingQuestion">提问</el-button>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { crete_new_window, ask_question, get_history_window, delete_chat_window, get_chats, ai_upload_file } from "@/network/ai";
import { request_get_information } from "@/network/profile";
export default {
  name: "AiHome",
  data() {
    return {
      currentLineIndex: 0,
      currentCharIndex: 0,
      typingInterval: null,
      defaultQ: {
        1: '请帮我制定一个从大一开始的全流程保研计划。', 2: '预推免需要如何高效准备，乘胜追击，逆风翻盘。',
        3: '编程小白如何从0开始学习算法竞赛？', 4: '如何高效准备蓝桥杯，一个月冲省一？',
        5: '临近期末考试，怎么高效复习数据结构？', 6: '请帮我列出操作系统的重要考点。'
      },
      windows: [{
        title: '新窗口',
        name: '1',
        btnVisible: false,
        windowId: '1',
      }],
      windowsLoading: true,
      question: '',
      totalWindow: 1,
      activeIndex: '-1',
      selectedMenuItem: '',
      currentWinId: 0,
      chatsLoading: true,
      sendingQuestion: false,
      displayText: '',
      lines: [],
      nickname: '我',
      currentChat: [
        {
          "answer": "加载中",
          "dialogueId": 1, "push_time": "2024/08/14 01:40", "question": "加载中", "windowId": 1
        },
      ],
      isDrag: false,
      fileList: [],
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    handleSelect(key) {
      if (this.activeIndex !== key) {
        this.chatsLoading = true;
        this.activeIndex = key;
        this.currentWinId = this.activeIndex;
        get_chats(this.email, this.currentWinId).then((res) => {
          this.currentChat = res.dialogue;
          this.currentChat.forEach(chat => {
            chat['showTyping'] = false;
          });
          this.$nextTick(() => {
            this.chatsLoading = false;
            let scrollElem = this.$refs.scrollDiv;
            scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
          });
        });
      }
    },
    handleAskQuestion() {
      this.sendingQuestion = true;
      ask_question(this.email, this.question, 7, this.currentWinId).then((res) => {
        this.currentChat.push({ question: this.question, answer: res.answer, showTyping: true });
        this.question = '';
        this.sendingQuestion = false;
        this.$nextTick(() => {
          let scrollElem = this.$refs.scrollDiv;
          scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
        });
        this.lines.push(res.answer);
        this.startTyping();
      })
    },


    handleExceed(files, fileList) {
      this.$message.warning('最多只能选择一个文件');
    },
    onFileChange(file, fileList) {
      console.log(file, fileList);
      this.fileList = fileList;
    },

    handleRemove() {
      this.fileList = [];
    },
    handleFlieQuestion() {
      this.sendingQuestion = true;
      console.log(this.fileList);
      const formData = new FormData();
      formData.append('email', this.email);
      formData.append('question', this.question);
      formData.append('typeId', 0);
      formData.append('windowId', this.currentWinId);
      formData.append("file", this.fileList[0].raw);
      const config = {
        headers: { "Content-Type": "multipart/form-data" }, //跟后端约定发送的数据类型为form-data对象类型
      };
      ai_upload_file(formData, config).then((res) => {
        console.log(res);
        this.currentChat.push({ question: this.question, answer: res.answer, showTyping: true });
        this.question = '';
        this.sendingQuestion = false;
        this.handleRemove()
        this.$nextTick(() => {
          let scrollElem = this.$refs.scrollDiv;
          scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
        });
        this.lines.push(res.answer);
        this.startTyping();
      })
    },

    handleDefaultQ(typeId) {
      ask_question(this.email, this.defaultQ[typeId], typeId, this.currentWinId).then((res) => {
        this.currentChat.push({ question: this.defaultQ[typeId], answer: res.answer, showTyping: true });
        this.$nextTick(() => {
          let scrollElem = this.$refs.scrollDiv;
          scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
        });
      })
    },
    handleAddWindow() {
      let access = this.$store.getters.access_type;
      if (access !== undefined && access < 1 && this.windows.length > 2) { // access:= 0 =>默认免费版，access:= -1 =>未登录
        this.$message({
          type: 'info',
          message: '免费版只能保存三个窗口，如想添加新窗口请先删除其他窗口'
        });
      }
      else {
        this.addWindow();
      }
    },
    addWindow() {
      this.windowsLoading = true;
      this.chatsLoading = true;
      crete_new_window(this.email).then((res) => {
        this.windows.push({
          title: '新窗口' + this.totalWindow,
          name: res.windowId.toString(),
          btnVisible: false,
          windowId: res.windowId.toString(),
        });
        this.activeIndex = res.windowId.toString();
        this.currentWinId = this.activeIndex;
        this.currentChat = [];
        this.totalWindow++;
        this.chatsLoading = false;
        this.windowsLoading = false;
      })
    },
    handleDeleteWindow(windowId) {
      this.windowsLoading = true;
      this.$confirm('是否确认删除此窗口', '系统提示', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        type: 'info'
      }).then(() => {
        delete_chat_window(this.email, windowId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '删除窗口成功'
            });
            if (windowId.toString() === this.activeIndex.toString()) {
              this.activeIndex = '-1';
              this.chatsLoading = true;
            }
            this.fetchWindows('');
            this.$forceUpdate();
          }
          else {
            this.$message({
              type: 'warning',
              message: '删除窗口成功失败，请稍后重试'
            });
          }
        });
      }).catch(() => { });
    },
    fetchWindows(from) {
      get_history_window(this.email).then((res) => {
        if (res !== undefined && res.history !== undefined) {
          this.windows = res.history;
          if (this.windows.length > 0) {
            if (from === 'created') {
              if (this.$store.getters.prev_window != undefined && this.$store.getters.prev_window !== '-1') {
                this.activeIndex = this.$store.getters.prev_window.toString();
              }
              else {
                this.activeIndex = this.windows[0].windowId.toString();
              }
            }
            else {
              this.activeIndex = this.activeIndex === '-1' ? this.windows[0].windowId.toString() : this.activeIndex;
            }
            if (this.currentWinId !== this.activeIndex) {
              this.currentWinId = this.activeIndex;
              this.windows.forEach(win => {
                win['windowId'] = win['windowId'].toString();
              });
              get_chats(this.email, this.currentWinId).then((res) => {
                this.currentChat = res.dialogue;
                this.currentChat.forEach(chat => {
                  chat['showTyping'] = false;
                });
                this.$nextTick(() => {
                  let scrollElem = this.$refs.scrollDiv;
                  scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
                  this.$forceUpdate();
                });
              });
            }
            this.totalWindow = res.history.length;
          }
          else {
            this.addWindow();
          }
        }
        else {
          this.windows = [];
          this.addWindow();
        }
        this.$forceUpdate();
        this.chatsLoading = false;
        this.windowsLoading = false;
      })
    },
    goToReport() {
      this.$store.commit("SET_PREV_WINDOW", this.currentWinId);
      this.$router.push('/ai/personal_report');
    },
    enter(item) {
      item.btnVisible = item.windowId != this.currentWinId;
      this.$forceUpdate();
    },
    leave(item) {
      item.btnVisible = false;
      this.$forceUpdate();
    },
    typeLine() {
      if (this.currentCharIndex < this.lines[this.currentLineIndex].length) {
        // 逐字显示当前行的文本
        this.displayText += this.lines[this.currentLineIndex][this.currentCharIndex];
        this.currentCharIndex++;
      } else {
        // 当前行显示完毕，准备显示下一行
        this.displayText += '\n'; // 添加换行符
        this.currentLineIndex++;
        this.currentCharIndex = 0;
        if (this.currentLineIndex >= this.lines.length) {
          // 所有行显示完毕，重置并重新开始
          this.currentChat[this.currentChat.length - 1].showTyping = false;
          this.currentLineIndex = 0;
          this.lines = [];
          this.displayText = '';
          clearInterval(this.typingInterval); // 清除定时器
          // setTimeout(this.startTyping, 5000); // 等待5秒后重新开始
        }
      }
    },
    startTyping() {
      this.displayText = '';
      if (this.typingInterval) {
        clearInterval(this.typingInterval);
      }
      this.typingInterval = setInterval(this.typeLine, 200);//这里调整打字速度
    },
  },
  beforeDestroy() {
    clearInterval(this.typingInterval); // 组件销毁前清除定时器
    this.$store.commit("SET_PREV_WINDOW", this.currentWinId);
  },
  created() {
    this.email = this.$store.getters.email;
    request_get_information(this.email).then((res) => {
      if (res !== undefined && res !== null) {
        if (res.nickname !== undefined && res.nickname !== null) {
          this.nickname = res.nickname;
        }
      }
    })
    this.fetchWindows('created');
  }
}
</script>

<style scoped>
.home {
  overflow: hidden;
}

.el-container {
  margin: 0px;
}

.container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.el-main {
  background-color: #f3e6f6;
  color: #333;
  text-align: center;
  flex: 1;
  overflow: hidden;
}

.belowTop {
  text-align: center;
  margin-top: 60px;
  height: calc(100vh - 60px);
  flex: 1;
}

.el-page-header {
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 20px;
  width: 100%;
  height: 60px;
  justify-content: start;
  align-items: center;
  background-color: #7a557b;
  color: white;
  overflow-y: hidden;
  z-index: 1000;
}

::v-deep .el-page-header__content {
  color: white;
}

.boxes {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex: 1;
}

.box {
  /* flex: 1; */
  border: 1px solid #ccc;
  overflow-y: auto;
  border-radius: 10px;
  margin: 0 auto;
}

.el-menu,
.el-menu-item {
  overflow-y: hidden;
}

.menu {
  width: 15%;
  background-color: #ffffff;
}

.chatScreen {
  width: 80%;
  background-color: #ffffff;
  height: calc(100vh - 130px);
  border-radius: 5px;
  overflow: auto;
}

.newScreen {
  margin-bottom: 80px;
}

.intro {
  margin: 30px 0;
  font-size: 30px;
}

.inputDiv {
  background-color: white;
  height: 50px;
  width: 61%;
  position: absolute;
  bottom: 50px;
  left: 27%;
}

.dragDiv {
  background-color: white;
  box-sizing: border-box;
  height: 300px;
  width: 60%;
  position: absolute;
  bottom: 50px;
  left: 27%;
  border: 1px solid #7a557b;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.drag-box {
  width: 100%;
}

.file-info{
  flex: 1;
  margin: 20px 0;
  border: 1px dashed #d9d9d9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

:deep(.el-upload) {
  width: 100%;
}

:deep(.el-upload-dragger) {
  width: 100%;
}

.flie_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flie_question_btn {
  height: 40px;
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  color: #f3e6f6;
  background-color: #7a557b;
  border: none;
  margin-left: 20px;
}

.chats {
  margin-bottom: 60px;
}

.flieDiv {
  margin-bottom: 310px;
}

.inputBox {
  position: absolute;
  bottom: 15px;
  left: 10%;
  width: 80%;
}

.iconBtnDiv {
  display: flex;
  align-items: center;
  float: right;
  height: 18px;
  width: 24px;
}

.fileBtn {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  float: left;
  color: #f3e6f6;
  background-color: #7a557b;
  border: none;
  margin-left: 10px;
}

.sendQuestionBtn {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  float: right;
  color: #f3e6f6;
  background-color: #7a557b;
  border: none;
}

.menuSpan {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-y: hidden;
}

.iconBtn {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  font-size: 2px;
  margin: 0;
  padding: 0;
  border: none;
  color: #7a557b;
  background-color: transparent;
  position: absolute;
  top: 20px;
}

.delBtn {
  right: 0;
}

.editBtn {
  left: 0;
  margin-left: 10px;
}

.btns {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  color: #7a557b;
  height: 40px;
}

.btns:hover,
.sendQuestionBtn:hover {
  color: #7a557b;
  background-color: #f3e6f6;
  border: none;
}

.newWinBtn {
  background-color: #7a557b;
  color: #f3e6f6;
  border: none;
  /* margin: 20px 0; */
  position: fixed;
  bottom: 70px;
  left: 15.5%;
}

::v-deep .el-menu-item:hover {
  background-color: #f3e6f6 !important;
}

::v-deep .el-menu-item:focus,
::v-deep .el-menu-item:active {
  background-color: #f7f1f9 !important;
}

.chatBlockDiv {
  display: inline-block;
  width: 100%;
}

.myselfSpeaker {
  border: 1px solid #f3e6f6;
  box-shadow: 3px 4px #f3e6f6;
}

.aiSpeaker {
  border: 1px solid #7a557b;
  box-shadow: 3px 4px #7a557b;
}

.speaker {
  float: left;
  margin: 30px 15px 20px 15px;
  width: 80px;
  padding: 5px;
  color: #7a557b;
  border-radius: 5px;
}

.chatCards {
  margin: 20px;
}

.ansCard {
  width: 85%;
  /* margin-left: 10px; 
    margin-right: auto; */
}

.qCard {
  width: 85%;
  /* margin-left: auto; 
    margin-right: 10px; */
}

.cardDiv {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  overflow: hidden;
  flex-wrap: wrap;
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 1.6;
}

.el-card {
  text-align: start;
  display: flex;
}

.nonBtn {
  font-size: 18px;
  cursor: default;
  border: none;
  width: 250px;
  margin: 0 20px 20px 0;
  background-color: #7a557b;
  color: #f3e6f6;
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  height: 40px;
}

.el-button {
  font-weight: 600;
}

.longChatBtns {
  font-size: 16px;
  white-space: pre-wrap;
  height: 100px;
  background-color: white;
  color: #7a557b;
  width: 250px;
  margin: 0 20px 20px 0;
}

.longChatBtns:hover {
  color: #f3e6f6;
  background-color: #7a557b;
}

.lastBtn {
  margin-right: 0;
}

.reportBtn {
  font-size: 16px;
  width: 800px;
  background-color: #7a557b;
  color: #f3e6f6;
}

::v-deep .el-input__inner,
::v-deep .el-textarea__inner {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
}

::v-deep .el-input__inner:focus,
::v-deep .el-textarea__inner:focus {
  border-color: #7a557b;
}

.cursor {
  animation: blink 1s infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
</style>